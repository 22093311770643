.root {
    display: flex;
    flex-wrap: wrap;
    padding: 50px;
}

.card {
    border: 2px solid #E5E5E5;
    padding: 15px;
    max-width: 700px;
    width: 45%;
    margin-right: 50px;
    margin-bottom: 50px;
    box-sizing: border-box;
    box-shadow: none !important;
    border-radius: 8px !important;
}

.title {
    font-size: 20px;
    font-weight: 900;
}

.description {
    margin-top: 20px;
}

.actions {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.questionHead {
    display: flex;
    align-items: center;
    font-weight: 600;
}

.questionHead img {
    width: 24px;
    margin-right: 10px;
}

.questionHr {
    height: 1px;
    width: Calc(100% + 60px);
    background-color: #b7aeae33;
    margin: 31px 0;
    margin-left: -30px;
}